const schema = {
  mapper: {
    settings: [
      {
        name: "useGravitoBackend",
        type: "checkbox",
        label: "Use Gravito Backend",
        tooltip:
          "Check this box if you want to enable Gravito backend capabilities with your CMP",
        required: false,
        default: false,
        xs: 12,
        sm: 12,
      },
      {
        name: "backendUrl",
        type: "text",
        label: "Backend URL",
        placeholder: "Enter your backend URL",
        tooltip:
          "This is the gravito backend Url where you want to store your consents.If you want to evaluate url depending upon the hosting domain enter dynamic .leave this field if you are not using gravito's backend capabilities",
        required: false,
        default: "",
        xs: 12,
        sm: 12,
      },
      {
        name: "catchCMPEvents",
        type: "checkbox",
        label: "Catch events emitted by cmp",
        tooltip:
          "Check this box If you want to get realtime data of CMP events on gravito admin dashboard",
        required: false,
        default: true,
        xs: 12,
        sm: 12,
      },
      {
        name: "userIdModule",
        type: "checkbox",
        label: "Use user Id Module",
        tooltip:
          "Check this box If you want to use userId module of gravito for prebid",
        required: false,
        default: false,
        xs: 12,
        sm: 12,
      },
      {
        name: "useGCM",
        type: "hidden",
        label: "Use GCM mode",
        tooltip:
          "Check this box if you want to enable Google consent mode with your CMP",
        required: false,
        advanced: false,
        default: true,
        xs: 12,
        sm: 12,
      },
      {
        name: "useACM",
        type: "checkbox",
        label: "Use Additional Consent Mode",
        tooltip: "Check this box If you want to use additional consent mode.",
        required: false,
        default: false,
        xs: 12,
        sm: 12,
      },
      {
        name: "type",
        type: "hidden",
        default: "TCF",
      },
      {
        name: "sdkVersion",
        type: "hidden",
        default: 3,
      },
      {
        name: "version",
        type: "hidden",
        default: "bundle_latest_5",
      },
    ],
    core: [
      {
        name: "cookieName",
        type: "text",
        label: "Cookie name",
        placeholder: "Enter your cookie name",
        tooltip: "Name of cookie in which you want to store your TC string",
        required: false,
        default: "TcString",
        xs: 12,
        sm: 6,
      },
      {
        name: "cookieExpiry",
        type: "number",
        label: "Cookie Expiry",
        placeholder: "Enter your cookie expiry",
        tooltip: "Expiry of tcstring cookie in days",
        required: false,
        default: 365,
        xs: 12,
        sm: 6,
      },
      {
        name: "rejectAllCookieExpiry",
        type: "number",
        label: "Reject All Cookie Expiry",
        placeholder: "Enter your cookie expiry for Reject All action",
        tooltip:
          "Expiry of tcstring cookie in days in case of Reject All action",
        required: false,
        default: 365,
        xs: 12,
        sm: 6,
      },
      {
        name: "syncAfter",
        type: "number",
        label: "Sync After(in seconds)",
        placeholder: "Enter sync after",
        tooltip:
          "This is the delay in seconds till which backend sync will not occur even after occurence of sync events",
        required: false,
        default: 1800,
        xs: 12,
        sm: 6,
      },
      {
        name: "settingBtnClassname",
        type: "text",
        label: "Setting Button classname",
        placeholder: "Enter your setting button classname",
        tooltip:
          "Using gravito CMP you can specifiy the css class of the elements thorugh which you want to show or hide CMP.",
        required: false,
        default: "manageSettings",
        xs: 12,
        sm: 6,
      },
      {
        name: "version",
        type: "number",
        label: "Version",
        placeholder: "Enter version",
        tooltip:
          "Version Number of your config, CMP will resurface whenever their change in version number",
        required: false,
        default: 2,
        xs: 12,
        sm: 6,
      },
      {
        name: "googleTcfId",
        type: "number",
        label: "google TCF Id",
        placeholder: "Enter google TCF Id",
        tooltip:
          "Click on the checkbox if you want to use gravito with backend capability",
        required: false,
        default: 755,
        xs: 12,
        sm: 6,
      },
      {
        name: "publisherCountryCode",
        type: "hidden",
        label: "Country Code",
        placeholder: "Enter your country code",
        tooltip: "Country code of publisher",
        required: false,
        default: "EN",
        xs: 12,
        sm: 6,
      },
      {
        name: "languageCode",
        type: "hidden",
        default: "en",
      },
      {
        name: "useTopDomain",
        type: "checkbox",
        label: "Do you want to write cookie in top domain",
        tooltip:
          "Writing cookie in top domain helps you to share cookie across your subdomains",
        required: false,
        default: false,
        xs: 12,
        sm: 12,
      },
      {
        name: "purposeOneTreatment",
        type: "checkbox",
        label: "Purpose one treatment",
        tooltip:
          "There is no special Purpose 1 status. Purpose 1 was disclosed normally (consent) as expected by Policy. true Purpose 1 not disclosed at all. CMPs use PublisherCC to indicate the publisher’s country of establishment to help Vendors determine whether the vendor requires Purpose 1 consent. In global scope TC strings, this field must always have a value of false. When a CMP encounters a global scope string with purposeOneTreatment=true then that string should be considered invalid and the CMP must re-establish transparency and consent.",
        required: false,
        default: false,
        xs: 12,
        sm: 12,
      },
      {
        name: "legitimateInterestDefaultBehavaior",
        type: "checkbox",
        label: "Default Value for Legitimate Interest",
        tooltip:
          "If this is set to false then legitimate intereset fields will be set to false by default, When UI is rendered for the first time. ",
        required: false,
        default: true,
        xs: 12,
        sm: 12,
      },
      {
        name: "purposes",
        type: "multiSelect",
        label: "Purpose IDs",
        internalType: "purposes",
        placeholder: "Select your purpose ids",
        options: "gvlData.purposes",
        tooltip: "Ids of Purposes in GVL",
        default: [
          {
            type: "purposes",
            id: 1,
          },
          {
            type: "purposes",
            id: 10,
          },
        ],
        xs: 12,
        sm: 12,
      },
      {
        name: "purposes",
        type: "multiSelect",
        label: "Stacks IDs",
        internalType: "stacks",
        placeholder: "Select your stacks ids",
        options: "gvlData.stacks",
        tooltip: "Ids of stacks in GVL",
        default: [
          {
            type: "stacks",
            id: 26,
          },
        ],
        xs: 12,
        sm: 12,
      },
      {
        name: "necessaryLegitimatePurposes",
        type: "multiSelect",
        label: "Necessary Legitimate Purpose IDs",
        placeholder: "Select your necessary legitimate purpose ids",
        options: "gvlData.purposes",
        limitedOptions: [2, 7, 9, 10],
        tooltip: "Ids of Necessary Legitimate Purpose",
        default: [],
        xs: 12,
        sm: 12,
      },
      {
        name: "specialFeatures",
        type: "multiSelect",
        label: "Special Feature IDs",
        placeholder: "Select your special feature ids",
        options: "gvlData.specialFeatures",
        tooltip: "Ids of Special features in GVL",
        default: [1, 2],
        xs: 12,
        sm: 12,
      },
      {
        name: "specialPurposes",
        type: "multiSelect",
        label: "Special Purpose IDs",
        placeholder: "Select your special Purpose ids",
        options: "gvlData.specialPurposes",
        tooltip: "Ids of Special Purpose in GVL",
        default: [1, 2],
        xs: 12,
        sm: 12,
      },
      {
        name: "features",
        type: "multiSelect",
        label: "Feature IDs",
        placeholder: "Select your Feature ids",
        options: "gvlData.features",
        tooltip: "Ids of Feature in GVL",
        default: [1, 2, 3],
        xs: 12,
        sm: 12,
      },
      {
        name: "vendors",
        type: "multiSelect",
        label: "Vendor IDs",
        placeholder: "Select your Vendor ids",
        options: "gvlData.vendors",
        tooltip: "Ids of Vendor in GVL",
        default: [2, 6, 8, 511, 11, 14, 278, 755],
        xs: 12,
        sm: 12,
      },
      {
        name: "googleVendors",
        type: "multiSelect",
        label: "Google Vendor IDs",
        placeholder: "Select your Google Vendor ids",
        options: "gvlData.googleVendors",
        tooltip: "Ids of Google Vendor.",
        default: [],
        xs: 12,
        sm: 12,
      },
      {
        name: "GCMConfig",
        type: "GCMConfig",
        label: "GCM-V2 Setup",
        helperText:
          "Using this section you can configure the Google Consent Mode V2. Please select mapping for google required consents.",
        fields: [
          {
            name: "adsConsentId",
            type: "multiSelect",
            label: "Ads consent IDs",
            internalType: "purposes",
            placeholder: "Select your ads consent ids",
            tooltip: "Ids of Ads consent in GVL",
            options: "gvlData.purposes",
            default: [1],
            required: false,
            xs: 12,
            sm: 12,
          },
          {
            name: "adsUserDataConsentId",
            type: "multiSelect",
            label: "User data consent IDs",
            internalType: "purposes",
            placeholder: "Enter User Data consent IDs",
            tooltip:
              "It is the id of User Data consent that will be shown on second layer of TCF CMP.",
            options: "gvlData.purposes",
            required: false,
            default: [2, 11],
            xs: 12,
            sm: 12,
          },
          {
            name: "adsPersonalizationConsentId",
            type: "multiSelect",
            label: "Personalization consent IDs",
            placeholder: "Enter Personalization consent IDs",
            tooltip:
              "It is the id of Personalization consent that will be shown on second layer of TCF CMP.",
            options: "gvlData.purposes",
            required: false,
            default: [3, 4, 5, 6],
            xs: 12,
            sm: 12,
          },
          {
            name: "analyticsConsentId",
            type: "multiSelect",
            label: "Analytics consent IDs",
            placeholder: "Enter analytics consent IDs",
            tooltip:
              "It is the ID of Analytics consent that will be shown on second layer of light CMP.",
            options: "gvlData.purposes",
            required: false,
            default: [1, 9, 10],
            xs: 12,
            sm: 12,
          },
        ],
        required: false,
        advanced: false,
        xs: 12,
        sm: 12,
      },
      {
        name: "nonTCFVendors",
        type: "nonTCFVendors",
        label: "Non tcf vendors",
        helperText:
          "Using this section you can configure Non TCF vendors for your CMP",
        required: false,
        default: {
          en: [
            {
              type: "nonTCFVendors",
              id: 1,
              name: "Facebook",
              description: "description for facebook",
              isConsentable: true,
            },
            {
              type: "nonTCFVendors",
              id: 2,
              name: "Amazon",
              description: "description for amazon",
              isConsentable: false,
            },
          ],
          fi: [
            {
              type: "nonTCFVendors",
              id: 1,
              name: "FIAM-kävijämittaus",
              description: "FIAM-kävijämittauksen selite",
              isConsentable: true,
            },
            {
              type: "nonTCFVendors",
              id: 2,
              name: "Amazon",
              description: "Amazon markkinointi/adtech palveluiden kuvaus",
              isConsentable: true,
            },
          ],
          sv: [
            {
              type: "nonTCFVendors",
              id: 1,
              name: "Facebook",
              description:
                'Facebook samlar in data om vilka webbsidor du besöker och om innehåll också utanför Facebook. Facebook används för reklam och den insamlade informationen för mer målgruppsanpassad reklam.<br><br><a href="https://www.facebook.com/help/568137493302217">https://www.facebook.com/help/568137493302217</a>',
              isConsentable: true,
            },
            {
              type: "nonTCFVendors",
              id: 2,
              name: "Twitter",
              description:
                "Twitter samlar in data om vilka webbsidor du besöker och om innehåll också utanför Twitter. Twitter används för reklam och den insamlade informationen för mer målgruppsanpassad reklam.",
              isConsentable: false,
            },
          ],
          nl: [
            {
              type: "nonTCFVendors",
              id: 1,
              name: "Facebook",
              description: "description for facebook",
              isConsentable: true,
            },
            {
              type: "nonTCFVendors",
              id: 2,
              name: "Amazon",
              description: "description for amazon",
              isConsentable: false,
            },
          ],
        },
        xs: 12,
        sm: 12,
      },
      {
        name: "customPurposes",
        type: "customPurposes",
        label: "Custom purposes",
        helperText:
          "Using this section you can configure the custom purposes for your CMP.",
        required: false,
        default: {
          en: [
            {
              type: "customPurpose",
              id: 1,
              name: "Data Collection",
              description:
                "Gravito can collect data and can use it for better experience of its customers",
              descriptionLegal:
                "Gravito can collect data and can use it for better experience of its customers",
              isLegitimate: true,
              isConsentable: true,
            },
            {
              type: "customPurpose",
              id: 2,
              name: "Targeting",
              description: "Gravito can use data for targeting its customers",
              descriptionLegal:
                "Gravito can use data for targeting its customers",
              isLegitimate: false,
              isConsentable: true,
            },
          ],
          fi: [
            {
              type: "customPurpose",
              id: 1,
              name: "Tietojen keräys",
              description:
                "Palvelu voi kerätä tietoja verkkokäyttäytymisestä ja käyttää niitä käyttäjäkokemuksen parantamiseksi.",
              descriptionLegal:
                "Palvelu voi kerätä tietoja verkkokäyttäytymisestä ja käyttää niitä käyttäjäkokemuksen parantamiseksi.",
              isLegitimate: true,
              isConsentable: true,
            },
            {
              type: "customPurpose",
              id: 2,
              name: "Kohdentaminen",
              description:
                "Palvelu voi käyttää keräämiään tietoja sisällön personointiin ja kohdentamiseen.",
              descriptionLegal:
                "Palvelu voi käyttää keräämiään tietoja sisällön personointiin ja kohdentamiseen.",
              isLegitimate: false,
              isConsentable: true,
            },
          ],
          sv: [
            {
              type: "customPurpose",
              id: 1,
              name: "Insamling av data",
              description:
                " kan samla in data om onlinebeteende och använda dessa för att förbättra användarupplevelsen",
              descriptionLegal:
                "Gravito kan samla in data om onlinebeteende och använda dessa för att förbättra användarupplevelsen",
              isLegitimate: true,
              isConsentable: true,
            },
            {
              type: "customPurpose",
              id: 2,
              name: "Inriktning",
              description:
                "Gravito kan använda data för att rikta in sig på sina kunder",
              descriptionLegal:
                "Gravito kan använda data för att rikta in sig på sina kunder",
              isLegitimate: false,
              isConsentable: true,
            },
          ],
          nl: [
            {
              type: "customPurpose",
              id: 1,
              name: "Data Collection",
              description:
                "Gravito can collect data and can use it for better experience of its customers",
              descriptionLegal:
                "Gravito can collect data and can use it for better experience of its customers",
              isLegitimate: true,
              isConsentable: true,
            },
            {
              type: "customPurpose",
              id: 2,
              name: "Targeting",
              description: "Gravito can use data for targeting its customers",
              descriptionLegal:
                "Gravito can use data for targeting its customers",
              isLegitimate: false,
              isConsentable: true,
            },
          ],
        },
        xs: 12,
        sm: 12,
      },
      {
        name: "publisherRestrictions",
        type: "publisherRestrictions",
        label: "Publisher Restrictions",
        helperText: "You can add publisher restrictions on various purposes.",
        required: false,
        default: [
          {
            purposeID: 1,
            restrictionType: "REQUIRE_CONSENT",
            vendors: [6, 12],
          },
          {
            purposeID: 1,
            restrictionType: "REQUIRE_CONSENT",
            vendors: [8],
          },
          {
            purposeID: 2,
            restrictionType: "REQUIRE_CONSENT",
            vendors: [8],
          },
        ],
        xs: 12,
        sm: 12,
      },
    ],
    text: [
      {
        layerName: "First Layer",
        fields: [
          {
            name: "firstLayer.title",
            type: "text",
            label: "Title",
            placeholder: "Enter title",
            tooltip:
              "Heading or Title that will be shown on first layer of CMP.",
            required: false,
            default: {
              en: "We need your consent to provide personalized experience.",
              fi: "Tarvitsemme suostumuksesi tarjotaksemme personoitua palvelua",
              sv: "Bättre innehåll och tjänster med hjälp av cookies",
              nl: "We hebben uw toestemming nodig om een persoonlijkere ervaring te bieden",
            },
            xs: 12,
            sm: 12,
          },
          {
            name: "firstLayer.introductionText",
            type: "text",
            label: "Introduction text",
            placeholder: "Enter introduction text",
            multiline: true,
            tooltip:
              "This text will be use as introductory text on first layer of CMP",
            required: false,
            default: {
              en: "Gravito and its <span id='partners-link'>third-party vendors</span> collect personal data (e.g. IP address, device identifier) through the use of cookies and other technical methods which are storing and accessing data on your device to provide the best user experience and show targetted content and advertising.",
              fi: "Palvelu ja sen <span id='partners-link'>kolmannen osapuolen {VendorsCount}  toimittajat</span> keräävät henkilötietoja (esim. IP-osoite tai laitetunniste) käyttäen evästeitä ja muita teknisiä keinoja tietojen tallentamiseen ja lukemiseen laitteellasi tarjotakseen sinulle tarkoituksenmukaisia mainoksia ja parhaan mahdollisen asiakaskokemuksen.",
              sv: "Vi och våra {VendorsCount} <span id='partners-link'>tredjepartsleverantörer</span> samlar information med hjälp av cookies och enhetsidentifierare då du besöker vår sajt. Med hjälp av informationen kan vi utveckla vårt innehåll och våra tjänster.",
              nl: "Gravito en haar <span id='partners-link'>externe {VendorsCount} verkopers</span> verzamelen persoonlijke gegevens (bijv. IP-adres, toestelidentifier) door het gebruik van cookies en andere technologische methoden die gegevens op uw apparaat opslaan en openen, om u de beste gebruikerservaring te bieden en gerichte inhoud en reclame te kunnen tonen.",
            },
            xs: 12,
            sm: 12,
          },
          {
            name: "firstLayer.consentableItemDescription",
            type: "text",
            label: "Consentable Item Description",
            placeholder: "Enter consentable item description",
            tooltip:
              "This text will be use as Description of consentable item i.e purpose and special fetaures on first layer of CMP.",
            required: false,
            default: {
              en: "Gravito and its partners require consent for following:",
              fi: "Palvelu ja sen kumppanit tarvitsevat suostumuksesi seuraaviin:",
              sv: "Gravito och dess partners kräver samtycke för följande:",
              nl: "Gravito en haar partners hebben toestemming nodig voor het volgende:",
            },
            xs: 12,
            sm: 12,
          },
          {
            name: "firstLayer.legalFooter",
            type: "text",
            label: "Legal Footer text",
            placeholder: "Enter legal footer",
            tooltip:
              "This text will be placed at the bottom of first layer of CMP after consentable items list.Usually this text give disclamer about data used by vendors.",
            required: false,
            default: {
              en: 'By accepting, you are allowing data processing within the service, rejection can affect the user experience. Some third-party vendors might use their legitimate interest to operate, you can object that or change other settings at any time by selecting "Settings" at the bottom of the page.',
              fi: 'Hyväksymällä sallit tietojesi käsittelyn. Suostumuksesi koskee tätä palvelua, hyväksymättä jättäminen voi vaikuttaa asiakaskokemukseesi. Jotkut teknologiat saattavat perustella tietojen käsittelyä oikeutetulla edulla, voit vastustaa tätä tai muuttaa muita asetuksia klikkaamalla "Asetukset" linkkiä.',
              sv: 'Genom att acceptera tillåter du databehandling inom tjänsten, avslag kan påverka användarupplevelsen. Vissa tredjepartsleverantörer kan använda sitt berättigade intresse för att arbeta, du kan invända mot det eller ändra andra inställningar när som helst genom att välja "Inställningar" längst ner på sidan.',
              nl: 'Met het accepteren geeft u toestemming voor gegevensverwerking binnen de service. Afwijzing kan de gebruikerservaring beïnvloeden. Sommige externe leveranciers kunnen hun legitieme belang gebruiken: u kunt hiertegen bezwaar maken of andere instellingen op ieder moment wijzigen door onderaan de pagina naar "Instellingen" te gaan.',
            },
            xs: 12,
            sm: 12,
          },
          {
            name: "firstLayer.privacyPolicyUrl",
            type: "text",
            label: "Privacy Policy URL",
            placeholder: "Enter privacy policy URL",
            tooltip: "This will be the link to your privacy policy page.",
            required: false,
            default: "https://www.gravito.net/#privacy-policy",
            xs: 12,
            sm: 6,
          },
          {
            name: "firstLayer.privacyLabel",
            type: "text",
            label: "Privacy Policy URL Label",
            placeholder: "Enter privacy policy URL label",
            tooltip: "This will be label for your privacy policy url",
            required: false,
            default: {
              en: "Privacy Policy",
              fi: "Tietosuoja",
              sv: "Dataskydd",
              nl: "Privacybeleid",
            },
            xs: 12,
            sm: 6,
          },
          {
            name: "firstLayer.actions[0]",
            type: "text",
            label: "Settings Label",
            placeholder: "Enter settings label",
            tooltip:
              "This will be the label for settings button on first layer",
            required: false,
            default: {
              en: "Settings",
              fi: "Asetukset",
              sv: "Inställningar",
              nl: "Instellingen",
            },
            xs: 12,
            sm: 6,
          },
          {
            name: "firstLayer.actions[1]",
            type: "text",
            label: "Accept All Label",
            placeholder: "Enter accept all label",
            tooltip:
              "This will be the label for accept all button on first layer",
            required: false,
            default: {
              en: "Accept All",
              fi: "Hyväksy kaikki",
              sv: "Godkänn alla",
              nl: "Alles accepteren",
            },
            xs: 12,
            sm: 6,
          },
          {
            name: "firstLayer.actions[2]",
            type: "text",
            label: "Reject All Label",
            placeholder: "Enter reject all label",
            tooltip:
              "This will be the label for reject all button on first layer",
            required: false,
            default: { en: "", fi: "", sv: "", nl: "" },
            xs: 12,
            sm: 12,
          },
        ],
      },
      {
        layerName: "Second Layer",
        fields: [
          {
            name: "secondLayer.title",
            type: "text",
            label: "Title",
            placeholder: "Enter title",
            tooltip:
              "Text that will be the title for Second Layer of your CMP.",
            required: false,
            default: {
              en: "Gravito Setting",
              fi: "Suostumusasetukset",
              sv: "Inställningar",
              nl: "Gravito-instelling",
            },
            xs: 12,
            sm: 12,
          },
          {
            name: "secondLayer.introductionText",
            type: "text",
            label: "Introduction text",
            placeholder: "Enter introduction text",
            multiline: true,
            tooltip:
              "Text that will be the title for Second Layer of your CMP.",
            required: false,
            default: {
              en: "Please select from the following list of purposes to help us serve you better.",
              fi: "Meidän {VendorsCount} toimittajaa keräävät ja hyödyntävät sekä tallentavat laitteellesi erilaisia tietoja käytettäväksi meille tärkeisiin tarkoituksiin kuten esimerksiksi sisällön ja mainonnan kohdentamiseen. Tässä voit sallia tai muutta valintojasi toimittajakohtaisesti.",
              sv: "Våra {VendorsCount} tekniska prestatörer samlar och sparar data i din enhet för oss att kunna visa anpassade annonser och annat innehåll. Här kan du anpassa dina val per teknisk prestatör",
              nl: "Onze {VendorsCount} verkopers slaan informatie op uw apparaat op en/of hebben toegang tot gegevens op uw apparaat voor het u kunnen geven van gepersonaliseerde advertenties en inhoud. Maak een keuze uit de volgende lijst met doeleinden om ons te helpen u beter van dienst te kunnen zijn.",
            },
            xs: 12,
            sm: 12,
          },
          {
            name: "secondLayer.copyTCstringTooltip",
            type: "text",
            label: "Copy TC string button's tooltip",
            placeholder: "Enter copy TC string button's tooltip",
            tooltip: "Translation for copy TC string button's tooltip.",
            required: false,
            default: {
              en: "Click here to copy TC string to clipboard",
              fi: "Klikkaamalla tästä voit kopioida CMP asetukset leikepöydälle",
              sv: "Klicka här för att kopiera TC-strängen till urklipp",
              nl: "Klik hier om de TC-string naar het klembord te kopiëren",
            },
            xs: 12,
            sm: 12,
          },
          {
            name: "secondLayer.tabLabels[0]",
            type: "text",
            label: "First Tab Label",
            placeholder: "Enter first tab label",
            tooltip:
              "This will be the label for first tab on second layer of CMP.",
            required: false,
            default: {
              en: "Purposes and Characteristics",
              fi: "Tarkoitukset ja ominaisuudet",
              sv: "Ändamål och egenskaper",
              nl: "Doeleinden en eigenschappen",
            },
            xs: 12,
            sm: 6,
          },
          {
            name: "secondLayer.tabLabels[1]",
            type: "text",
            label: "Second Tab Label",
            placeholder: "Enter second tab label",
            tooltip:
              "This will be the label for second tab on second layer of CMP.",
            required: false,
            default: {
              en: "Vendors",
              fi: "Toimittajat",
              sv: "Leverantörer",
              nl: "Verkopers",
            },
            xs: 12,
            sm: 6,
          },
          {
            name: "secondLayer.privacyPolicyUrl",
            type: "text",
            label: "Privacy Policy URL",
            placeholder: "Enter privacy policy URL",
            tooltip:
              "Privacy policy page url which is to be used on second layer.",
            required: false,
            default: "https://www.gravito.net/#privacy-policy",
            xs: 12,
            sm: 6,
          },
          {
            name: "secondLayer.privacyLabel",
            type: "text",
            label: "Privacy Policy URL Label",
            placeholder: "Enter privacy policy URL label",
            tooltip:
              "Privacy policy page url label which is to be used on second layer",
            required: false,
            default: {
              en: "Privacy Policy",
              fi: "Tietosuoja",
              sv: "Dataskydd",
              nl: "Privacybeleid",
            },
            xs: 12,
            sm: 6,
          },
          {
            name: "secondLayer.checkBoxLabels.consent",
            type: "text",
            label: "Checkbox labels for consents",
            placeholder: "Enter checkbox labels for consents",
            tooltip: "Check box labels for consent on second layer.",
            required: false,
            default: {
              en: "Consent",
              fi: "Hyväksy",
              sv: "Samtycke",
              nl: "Instemming",
            },
            xs: 12,
            sm: 6,
          },
          {
            name: "secondLayer.checkBoxLabels.legitimateInterest",
            type: "text",
            label: "Checkbox labels for legitimate interest",
            placeholder: "Enter checkbox labels for legitimate interest",
            tooltip:
              "Check box labels for legitimate Interest on second layer.",
            required: false,
            default: {
              en: "Legitimate Interest",
              fi: "Oikeutettu etu",
              sv: "Berättigat intresse\t",
              nl: "Legitiem belang",
            },
            xs: 12,
            sm: 6,
          },
          {
            name: "secondLayer.actions[0]",
            type: "text",
            label: "Accept All Label",
            placeholder: "Enter accept all label",
            tooltip:
              "This will be the label for accept all button on second layer",
            required: false,
            default: {
              en: "Accept All",
              fi: "Hyväksy kaikki",
              sv: "Godkänn alla",
              nl: "Alles accepteren",
            },
            xs: 12,
            sm: 6,
          },
          {
            name: "secondLayer.actions[1]",
            type: "text",
            label: "Accept Selected Label",
            placeholder: "Enter accept selected label",
            tooltip:
              "This will be the label for accept selected button on second layer",
            required: false,
            default: {
              en: "Accept Selected",
              fi: "Hyväksy valitut",
              sv: "Godkänn valda alternativ",
              nl: "Selectie accepteren",
            },
            xs: 12,
            sm: 6,
          },
          {
            name: "secondLayer.actions[2]",
            type: "text",
            label: "Reject All Label",
            placeholder: "Enter reject all label",
            tooltip:
              "This will be the label for reject all button on second layer",
            required: false,
            default: {
              en: "Reject All",
              fi: "Estä kaikki",
              sv: "Vägra alla",
              nl: "Alles afwijzen",
            },
            xs: 12,
            sm: 12,
          },
        ],
      },
      {
        layerName: "Confirmation Layer",
        fields: [
          {
            name: "thirdLayer.confirmationForUncheck.heading",
            type: "text",
            label: "Heading for confirmation for uncheck consent",
            placeholder: "Enter heading for confirmation for uncheck consent",
            tooltip:
              "This text will be heading of confirmation modal which will pop-up when user deny the consents.Will not be vissible if you disable confirmation modal.",
            required: false,
            default: {
              en: "Are you sure you want to disable?",
              fi: "Oletko varma?",
              sv: "Är du säker på att du inte vill ge ditt samtycke?",
              nl: "Weet u zeker dat u dit uit wilt schakelen?",
            },
            xs: 12,
            sm: 12,
          },
          {
            name: "thirdLayer.confirmationForUncheck.paragraphs[0]",
            type: "text",
            label: "Paragraph for confirmation for uncheck consent",
            placeholder: "Enter paragraph for confirmation for uncheck consent",
            multiline: true,
            tooltip:
              "This text will be content of confirmation modal which will pop-up when user deny the consents.Will not be vissible if you disable confirmation modal.",
            required: false,
            default: {
              en: "These cookies or other technical methods are important so that we can provide you with a better and more personal user experience.",
              fi: "Evästeet ja muut vastaavat tekniset menetelmät ovat tarpeellisia, jotta voimme tarjota sinulle paremman ja henkilökohtaisemman asiakaskokemuksen.",
              sv: "Dessa cookies eller andra tekniska medel är viktiga för att skapa den bästa personifierade kundupplevelsen.",
              nl: "Deze cookies en andere technologische methoden zijn belangrijk om u een betere en persoonlijkere gebruikerservaring te kunnen bieden.",
            },
            xs: 12,
            sm: 12,
          },
          {
            name: "thirdLayer.confirmationForAcceptSelected.heading",
            type: "text",
            label:
              "Heading for confirmation for Accept Selected uncheck consent",
            placeholder:
              "Enter heading for confirmation for Accept Selected uncheck consent",
            tooltip:
              "This text will be heading of confirmation modal which will pop-up when user clicks the save consents button.Will not be vissible if you disable confirmation modal.",
            required: false,
            default: {
              en: "Are you sure you want to disable?",
              fi: "Oletko varma?",
              sv: "Är du säker på att du inte vill ge ditt samtycke?",
              nl: "Weet u zeker dat u dit uit wilt schakelen?",
            },
            xs: 12,
            sm: 12,
          },
          {
            name: "thirdLayer.confirmationForAcceptSelected.paragraphs[0]",
            type: "text",
            label:
              "Paragraph for confirmation for Accept Selected uncheck consent",
            placeholder:
              "Enter paragraph for confirmation for Accept Selected uncheck consent",
            multiline: true,
            tooltip:
              "This text will be content of confirmation modal which will pop-up when user clicks the save consents button.Will not be vissible if you disable confirmation modal.",
            required: false,
            default: {
              en: "Cookies and other technical methods are important so that we can provide you with a better and more personal user experience. Without cookies or other technical methods, our ability to develop our services based on your preferences becomes more difficult, some features may be blocked, and your user experience may deteriorate.",
              fi: "västeet ja muut vastaavat tekniset menetelmät ovat tarpeellisia, jotta voimme tarjota sinulle paremman ja henkilökohtaisemman asiakaskokemuksen. Ilman evästeitä ja vastaavia menetelmiä käyttökokemuksesi voi olla heikompi.",
              sv: "Dessa cookies eller andra tekniska medel är viktiga för att skapa den bästa personfierade kundupplevelsen. Utan cookies eller motsvarande tekniska medel är det svårare för oss att utveckla våra tjänster och personifiera din kundupplevelse, vilket kan leda till att vissa egenskaper fattas och du får en sämre användarupplevelse.",
              nl: "Cookies en andere technologische methoden zijn belangrijk om u een betere en persoonlijkere gebruikerservaring te kunnen bieden.Zonder cookies of andere technische methoden is moeilijker voor ons om onze services te ontwikkelen op basis van uw voorkeuren; sommige functies werken wellicht niet en het kan derhalve uw gebruikerservaring verslechteren.",
            },
            xs: 12,
            sm: 12,
          },
          {
            name: "thirdLayer.actions[0]",
            type: "text",
            label: "Cancel Label",
            placeholder: "Enter cancel label",
            tooltip:
              "This will be the label for cancel button on confirmation modal",
            required: false,
            default: {
              en: "Cancel",
              fi: "Peruuta",
              sv: "Gå tillbaka",
              nl: "Annuleren",
            },
            xs: 12,
            sm: 6,
          },
          {
            name: "thirdLayer.actions[1]",
            type: "text",
            label: "Yes Label",
            placeholder: "Enter yes label",
            tooltip:
              "This will be the label for yes button on confirmation modal",
            required: false,
            default: { en: "Yes", fi: "Kyllä", sv: "Ja", nl: "Ja" },
            xs: 12,
            sm: 6,
          },
        ],
      },
      {
        layerName: "Cookie Report Layer",
        fields: [
          {
            name: "cookieReportLayer.buttonTitle",
            type: "text",
            label: "Button Title",
            placeholder: "Enter button title",
            tooltip:
              "Title for button which will be used for opening cookie report layer.",
            required: false,
            default: {
              en: "Cookie Report",
              fi: "Evästeraportti",
              sv: "Cookie rapport",
              nl: "Cookierapport",
            },
            xs: 12,
            sm: 6,
          },
          {
            name: "cookieReportLayer.heading",
            type: "text",
            label: "Heading",
            placeholder: "Enter heading",
            tooltip:
              "Text that should be used as heading for cookie report layer.",
            required: false,
            default: {
              en: "Cookie Reports layer header",
              fi: "Sivuston käyttämät evästeet",
              sv: "Webplatsen använder följande cookies",
              nl: "Header cookierapportlaag",
            },
            xs: 12,
            sm: 6,
          },
          {
            name: "cookieReportLayer.introductionText",
            type: "text",
            label: "Introduction Text",
            placeholder: "Enter introduction text",
            multiline: true,
            tooltip:
              "Text that should be used as introduction text for cookie report layer.",
            required: false,
            default: {
              en: "Cookies are small text files that websites can use to make the user experience more efficient. We use cookies on the website. We use cookies on this website to improve the visitor experience and to better serve you. Based on our scan, this is how the cookies that will be used based on the preference set by you.",
              fi: "Evästeitä käytetään sivuston teknisiin toimintoihin, käyttökokemuksen optimointiin ja käyttäjien seurantaan. Tämä raportti sisältää tiedot evästeistä joita sivusto käyttää joko teknisiin käyttötarkoituksiin (ennen suostumusta) ja evästeet ovat luonteeltaan pakollisia sivuston toiminnon kannalta, tai evästeitä käytetään suostumuksen jälkeen (käyttäjä hyväksyy kaikki käyttötarkoitukset). Riippuen suostumuksistasi kaikkia listattuja evästeitä ei välttämättä aseteta selaimeesi.",
              sv: "Webplatsen använder cookien för tekniska skäl för att optimera användarupplevelsen och följa användaren. Denna rapport innehåller information om cookien som används antingen för tekniska skäl (före samtycken)och är oblikatoriska för att webplatsen kan fungera och om cookien som används efter samtycken(användaren godkänner allt). Beroende på dina samtycken används inte alla listade cookien nödvändigtvis på din webläsare.",
              nl: "Cookies zijn kleine tekstbestanden die websites kunnen gebruiken om de gebruikerservaring efficiënter te maken. We gebruiken cookies op deze website om de bezoeker een betere ervaring te bieden en u beter van dienst te zijn. Op basis van onze scan worden de cookies op deze manier gebruikt, op basis van de door u ingestelde voorkeur.",
            },
            xs: 12,
            sm: 12,
          },
          {
            name: "cookieReportLayer.beforeAcceptHeader",
            type: "text",
            label: "Before accepting data heading",
            placeholder: "Enter heading",
            tooltip:
              "Text that should be used as Heading for the section cookie report layer which will be showing data before Accepting the consent.",
            required: false,
            default: {
              en: "Before Accepting",
              fi: "Evästeet ennen suostumusta (pakolliset ja toiminnalliset evästeet)",
              sv: "Cookien före samtycken (nödvändiga och funktionella cookien)",
              nl: "Voor het accepteren",
            },
            xs: 12,
            sm: 6,
          },
          {
            name: "cookieReportLayer.afterAcceptHeader",
            type: "text",
            label: "After accepting data heading",
            placeholder: "Enter heading",
            tooltip:
              "Text that should be used as heading for the section cookie report layer which will be showing data after Accepting the consent.",
            required: false,
            default: {
              en: "After Accepting",
              fi: "Evästeet suostumushyväksynnän jälkeen",
              sv: "Cookien efter samtycken",
              nl: "Na het accepteren",
            },
            xs: 12,
            sm: 6,
          },
          {
            name: "cookieReportLayer.nameHeader",
            type: "text",
            label: "Header for Name",
            placeholder: "Enter header for name",
            tooltip:
              "This text will be used as a header for name coloumn in cookie report layer.",
            required: false,
            default: { en: "Name", fi: "Nimi", sv: "Namn", nl: "Naam" },
            xs: 12,
            sm: 6,
          },
          {
            name: "cookieReportLayer.domainHeader",
            type: "text",
            label: "Header for domain",
            placeholder: "Enter header for domain",
            tooltip:
              "This text will be used as a header for domain coloumn in cookie report layer.",
            required: false,
            default: {
              en: "Domain",
              fi: "Domain-nimi",
              sv: "Domän",
              nl: "Domein",
            },
            xs: 12,
            sm: 6,
          },
          {
            name: "cookieReportLayer.descriptionHeader",
            type: "text",
            label: "Header for description",
            placeholder: "Enter header for description",
            tooltip:
              "This text will be used as a header for description coloumn in cookie report layer.",
            required: false,
            default: {
              en: "Description",
              fi: "Kuvaus",
              sv: "Syfte",
              nl: "Omschrijving",
            },
            xs: 12,
            sm: 6,
          },
          {
            name: "cookieReportLayer.cookieTypeHeader",
            type: "text",
            label: "Header for cookie type",
            placeholder: "Enter header for cookie type",
            tooltip:
              "This text will be used as a header for cookie type coloumn in cookie report layer.",
            required: false,
            default: {
              en: "Cookie Type",
              fi: "Tyyppi",
              sv: "Typ",
              nl: "Cookietype",
            },
            xs: 12,
            sm: 6,
          },
          {
            name: "cookieReportLayer.expiryHeader",
            type: "text",
            label: "Header for expiry",
            placeholder: "Enter header for expiry",
            tooltip:
              "This text will be used as a header for cookie expiry coloumn in cookie report layer.",
            required: false,
            default: {
              en: "Expiry in days",
              fi: "Elinikä",
              sv: "Livslängd",
              nl: "Vervalmoment in dagen",
            },
            xs: 12,
            sm: 6,
          },
          {
            name: "cookieReportLayer.httpOnlyHeader",
            type: "text",
            label: "Header for httpOnly",
            placeholder: "Enter header for httpOnly",
            tooltip:
              "This text will be used as a header for http only coloumn in cookie report layer.",
            required: false,
            default: {
              en: "Http Only",
              fi: "Palvelineväste",
              sv: "Server cookie",
              nl: "Alleen http",
            },
            xs: 12,
            sm: 6,
          },
          {
            name: "cookieReportLayer.sizeHeader",
            type: "text",
            label: "Header for size",
            placeholder: "Enter header for size",
            tooltip:
              "This text will be used as a header for size coloumn in cookie report layer.",
            required: false,
            default: {
              en: "Size",
              fi: "Koko",
              sv: "Storlek",
              nl: "Grootte",
            },
            xs: 12,
            sm: 6,
          },
          {
            name: "cookieReportLayer.noCookieDataMessage",
            type: "text",
            label: "No cookie data message",
            placeholder: "Enter no cookie data message",
            tooltip:
              "This text will be used as text when their is no cookie report data available.",
            required: false,
            default: {
              en: "No scan results available",
              fi: "Ei evästeskannauksen tuloksia saatavilla.",
              sv: "Inga cookie-scan resultat till hands.",
              nl: "Geen scanresultaten beschikbaar",
            },
            xs: 12,
            sm: 6,
          },
          {
            name: "cookieReportLayer.noCookieDataBeforeAcceptingMsg",
            type: "text",
            label: "No data message for before accepting section",
            placeholder: "Enter no cookie data message",
            tooltip:
              "This text will be used as text when their is no cookie report data available for  before accepting section.",
            required: false,
            default: {
              en: "No cookies were found before Accepting the consents on this domain.",
              fi: "Evästeitä ei löytynyt ennen suostumushyväksyntää.",
              sv: "Inga cookien hittades före samtycken.",
              nl: "Er zijn geen cookies gevonden voordat de toestemmingen op dit domein waren geaccepteerd.",
            },
            xs: 12,
            sm: 12,
          },
          {
            name: "cookieReportLayer.noCookieDataAfterAcceptingMsg",
            type: "text",
            label: "No data message for After accepting section",
            placeholder: "Enter no cookie data message",
            tooltip:
              "This text will be used as text when their is no cookie report data available for after accepting section.",
            required: false,
            default: {
              en: "No cookies were found after Accepting the consents on this domain.",
              fi: "Evästeitä ei löytynyt suostumushyväksynnän jälkeen.",
              sv: "Inga cookien hittades efter samtycken.",
              nl: "Er zijn geen cookies gevonden nadat de toestemmingen op dit domein waren geaccepteerd.",
            },
            xs: 12,
            sm: 12,
          },
        ],
      },
      {
        layerName: "Common Terms",
        fields: [
          {
            name: "commonTerms.purposes",
            type: "text",
            label: "Translation for purposes",
            placeholder: "Enter translation for purposes",
            tooltip: "Translation word for term purposes.",
            required: false,
            default: {
              en: "Purposes",
              fi: "Tarkoitukset",
              sv: "Syften",
              nl: "Doeleinden",
            },
            xs: 12,
            sm: 6,
          },
          {
            name: "commonTerms.consent",
            type: "text",
            label: "Translation for consent",
            placeholder: "Enter translation for consent",
            tooltip: "Translation word for term consent.",
            required: false,
            default: {
              en: "Consent",
              fi: "Suostumus",
              sv: "Samtycke",
              nl: "Instemming",
            },
            xs: 12,
            sm: 6,
          },
          {
            name: "commonTerms.legitimateInterest",
            type: "text",
            label: "Translation for legitimate interest",
            placeholder: "Enter translation for legitimate interest",
            multiline: true,
            tooltip: "Translation word for term legitimate interest.",
            required: false,
            default: {
              en: "Legitimate Interest",
              fi: "Oikeutettu etu",
              sv: "Berättigat intresse",
              nl: "Legitiem belang",
            },
            xs: 12,
            sm: 6,
          },
          {
            name: "commonTerms.specialPurposes",
            type: "text",
            label: "Translation for special purposes",
            placeholder: "Enter translation for special purposes",
            tooltip: "Translation word for term special purposes.",
            required: false,
            default: {
              en: "Special Purposes",
              fi: "Erityiset tarkoitukset",
              sv: "Specialsyften",
              nl: "Speciale doeleinden",
            },
            xs: 12,
            sm: 6,
          },
          {
            name: "commonTerms.specialFeatures",
            type: "text",
            label: "Translation for special features",
            placeholder: "Enter translation for special features",
            tooltip: "Translation word for term special features.",
            required: false,
            default: {
              en: "Special Features",
              fi: "Erityisominaisuudet",
              sv: "Specialfunktioner",
              nl: "Speciale functies",
            },
            xs: 12,
            sm: 6,
          },
          {
            name: "commonTerms.features",
            type: "text",
            label: "Translation for features",
            placeholder: "Enter translation for features",
            tooltip: "Translation word for term features.",
            required: false,
            default: {
              en: "Features",
              fi: "Ominaisuudet",
              sv: "Egenskaper",
              nl: "Functies",
            },
            xs: 12,
            sm: 6,
          },
          {
            name: "commonTerms.policyURl",
            type: "text",
            label: "Translation for policy URl",
            placeholder: "Enter translation for policy URl",
            tooltip: "Translation word for term policy URL.",
            required: false,
            default: {
              en: "Policy URL",
              fi: "Tietosuojakäytäntö",
              sv: "Integritetspolicy",
              nl: "Beleids-URL",
            },
            xs: 12,
            sm: 6,
          },
          {
            name: "commonTerms.nonTCFVendors",
            type: "text",
            label: "Translation for non-TCF vendors",
            placeholder: "Enter translation for non-TCF vendors",
            tooltip: "Translation word for term NON TCF vendors.",
            required: false,
            default: {
              en: "Non TCF Vendors",
              fi: "Toimittajat jotka eivät kuulu IAB:n TCF verkostoon",
              sv: "Leverantörer som inte hör till IAB:s nätverk TCF",
              nl: "Non-TCF-verkopers",
            },
            xs: 12,
            sm: 6,
          },
          {
            name: "commonTerms.vendors",
            type: "text",
            label: "Translation for vendors",
            placeholder: "Enter translation for vendors",
            tooltip: "Translation word for term vendors.",
            required: false,
            default: {
              en: "Vendors",
              fi: "Toimittajat",
              sv: "Leverantörer",
              nl: "Verkopers",
            },
            xs: 12,
            sm: 6,
          },
          {
            name: "commonTerms.customPurposes",
            type: "text",
            label: "Translation for custom purposes",
            placeholder: "Enter translation for custom purposes",
            tooltip: "Translation word for term custom purposes.",
            required: false,
            default: {
              en: "Custom Purposes",
              fi: "Mukautetut tarkoitukset",
              sv: "Tillstånd i anknytning till Gravito verksamhet",
              nl: "Aangepaste doeleinden",
            },
            xs: 12,
            sm: 6,
          },
          {
            name: "commonTerms.cookieMaxAge",
            type: "text",
            label: "Translation for cookie max age",
            placeholder: "Enter translation for cookie max age",
            tooltip: "Translation word for term cookie max age.",
            required: false,
            default: {
              en: "Cookie max age (seconds)",
              fi: "Evästeen säilytysaika",
              sv: "Kakans lagringstid (sekunder)",
              nl: "Maximumleeftijd cookie (seconden)",
            },
            xs: 12,
            sm: 6,
          },
          {
            name: "commonTerms.disclouserDetails",
            type: "text",
            label: "Translation for disclouser details",
            placeholder: "Enter translation for disclouser details",
            tooltip: "Translation word for term disclouser details.",
            required: false,
            default: {
              en: "Details",
              fi: "Yksityiskohdat",
              sv: "Detaljer",
              nl: "Details",
            },
            xs: 12,
            sm: 6,
          },
          {
            name: "commonTerms.disclouserFetchingError",
            type: "text",
            label: "Translation for disclouser fetching error",
            placeholder: "Enter translation for disclouser fetching error",
            tooltip: "Translation word for term disclouser fetching error.",
            required: false,
            default: {
              en: "Unable to get Json data",
              fi: "Virhe toimittajan lisätietojen noutamisessa",
              sv: "Json data inte tillgänglig",
              nl: "Kon json-gegevens niet ophalen",
            },
            xs: 12,
            sm: 6,
          },
          {
            name: "commonTerms.disclouserHeaderIdentifier",
            type: "text",
            label: "Translation for disclouser header(identifier)",
            placeholder: "Enter translation for disclouser header(identifier)",
            tooltip:
              "Translation word for the header of identifer column in discloser table.",
            required: false,
            default: {
              en: "Identifier",
              fi: "Tunniste",
              sv: "Identifierare",
              nl: "Identifier",
            },
            xs: 12,
            sm: 6,
          },
          {
            name: "commonTerms.disclouserHeaderDomain",
            type: "text",
            label: "Translation for disclouser header(domain)",
            placeholder: "Enter translation for disclouser header(domain)",
            tooltip:
              "Translation word for the header of domain column in discloser table.",
            required: false,
            default: {
              en: "Domain",
              fi: "Verkkotunnus",
              sv: "Domän",
              nl: "Domein",
            },
            xs: 12,
            sm: 6,
          },
          {
            name: "commonTerms.disclouserHeaderType",
            type: "text",
            label: "Translation for disclouser header type",
            placeholder: "Enter translation for disclouser header type",
            tooltip:
              "Translation word for the header of type column in discloser table.",
            required: false,
            default: { en: "Type", fi: "Tyyppi", sv: "Typ", nl: "Type" },
            xs: 12,
            sm: 6,
          },
          {
            name: "commonTerms.disclosureHeaderMaxAge",
            type: "text",
            label: "Translation for disclosure header max age",
            placeholder: "Enter translation for disclosure header max age",
            tooltip:
              "Translation word for the header of max age column in discloser table.",
            required: false,
            default: {
              en: "Max Age(s)",
              fi: "Max elinikä (s)",
              sv: "Max Ålder(s)",
              nl: "Maximum leeftijd(en)",
            },
            xs: 12,
            sm: 6,
          },
          {
            name: "commonTerms.disclosureHeaderPurpose",
            type: "text",
            label: "Translation for disclosure header purpose",
            placeholder: "Enter translation for disclosure header purpose",
            tooltip:
              "Translation word for the header of purpose column in discloser table.",
            required: false,
            default: {
              en: "Purposes",
              fi: "Tarkoi",
              sv: "Meningar",
              nl: "Doeleinden",
            },
            xs: 12,
            sm: 6,
          },
          {
            name: "commonTerms.cookieRefresh",
            type: "text",
            label: "Translation for cookie refresh label",
            placeholder: "Enter translation for cookie refresh label",
            tooltip: "Translation word for the label of cookie refresh.",
            required: false,
            default: {
              en: "Cookie Refresh",
              fi: "Evästeen päivittäminen",
              sv: "Kakans updatering",
              nl: "Cookieverversen",
            },
            xs: 12,
            sm: 6,
          },
          {
            name: "commonTerms.usesCookies",
            type: "text",
            label: "Translation for uses cookies label",
            placeholder: "Enter translation for uses cookies label",
            tooltip: "Translation word for term uses Cookies label.",
            required: false,
            default: {
              en: "Uses Cookies",
              fi: "Käyttää evästeitä",
              sv: "Använder kakor",
              nl: "Gebruikt cookies",
            },
            xs: 12,
            sm: 6,
          },
          {
            name: "commonTerms.usesNonCookieAccess",
            type: "text",
            label: "Translation for uses non cookie access label",
            placeholder: "Enter translation for uses non cookie access label",
            tooltip: "Translation word for term uses non cookie access label.",
            required: false,
            default: {
              en: "Uses Non Cookie Access",
              fi: "Käyttää jotain muuta tapaa",
              sv: "Använder ett annat sätt",
              nl: "Gebruikt toegang zonder cookies",
            },
            xs: 12,
            sm: 6,
          },
          {
            name: "commonTerms.yes",
            type: "text",
            label: "Translation for yes",
            placeholder: "Enter translation for yes",
            tooltip: "Translation word for term yes.",
            required: false,
            default: { en: "Yes", fi: "Kyllä", sv: "Ja", nl: "Ja" },
            xs: 12,
            sm: 6,
          },
          {
            name: "commonTerms.no",
            type: "text",
            label: "Translation for no",
            placeholder: "Enter translation for no",
            tooltip: "Translation word for term no.",
            required: false,
            default: { en: "No", fi: "Ei", sv: "Nej", nl: "Nee" },
            xs: 12,
            sm: 6,
          },
          {
            name: "commonTerms.seconds",
            type: "text",
            label: "Translation for seconds",
            placeholder: "Enter translation for seconds",
            tooltip: "Translation word for term seconds.",
            required: false,
            default: {
              en: "seconds",
              fi: "sekuntia",
              sv: "sekunder",
              nl: "seconden",
            },
            xs: 12,
            sm: 6,
          },
          {
            name: "commonTerms.days",
            type: "text",
            label: "Translation for days",
            placeholder: "Enter translation for days",
            tooltip: "Translation word for term days.",
            required: false,
            default: {
              en: "days",
              fi: "päivää",
              sv: "dagar",
              nl: "dagen",
            },
            xs: 12,
            sm: 6,
          },
          {
            name: "commonTerms.googleVendors",
            type: "text",
            label: "Translation for word google vendors",
            placeholder: "Enter translation for word google vendors",
            tooltip: "Translation word for  word google vendors",
            required: false,
            default: {
              en: "Google Vendors",
              fi: "Google Vendors",
              sv: "Google Vendors",
              nl: "Google Vendors",
            },
            xs: 12,
            sm: 6,
          },
          {
            name: "commonTerms.alertMessageForCopiedTcString",
            type: "text",
            label: "Message after succesfull copying of tc String",
            placeholder: "Enter message after succesfull copying of tc String",
            tooltip: "Message after succesfull copying of tc String.",
            required: false,
            default: {
              en: "CMP Settings Copied",
              fi: "CMP asetukset kopioitu leikepöydälle",
              sv: "CMP-inställningar kopierade",
              nl: "CMP-instellingen gekopieerd",
            },
            highlight: true,
            xs: 12,
            sm: 6,
          },
          {
            name: "commonTerms.legitVendorCount",
            type: "text",
            label: "Translation for Legitimate Vendors Count",
            placeholder: "Enter translation for Legitimate Vendors Count",
            tooltip: "Translation word for term Legitimate Vendors Count.",
            required: false,
            default: {
              en: "Legitimate Interest Vendors",
              fi: "Teknologiat, jotka ovat sivuston toiminnalle välttämättömiä",
              sv: "Nödvändiga teknologier för webbplatsens tekniska funktionalitet",
              nl: "Legitiem belang verkopers",
            },
            highlight: true,
            xs: 12,
            sm: 6,
          },
          {
            name: "commonTerms.consentVendorCount",
            type: "text",
            label: "Translation for Consent Vendors Count",
            placeholder: "Enter translation for Consent Vendors Count",
            tooltip: "Translation word for term Consent Vendors Count.",
            required: false,
            default: {
              en: "Consent Vendors",
              fi: "Teknologiat, jotka tarvitsevat lupasi toimiakseen oikein",
              sv: "Teknologier som behöver ditt samtycke för att funktionera",
              nl: "Toestemming verkopers",
            },
            highlight: true,
            xs: 12,
            sm: 6,
          },
          {
            name: "commonTerms.stdRetention",
            type: "text",
            label: "Translation for Std retention",
            placeholder: "Enter translation for Std retention",
            tooltip: "Translation word for term Std retention.",
            required: false,
            default: {
              en: "Std retention",
              fi: "Normaali säilyvyys",
              sv: "Standard lagringstid",
              nl: "Std-retentie",
            },
            highlight: true,
            xs: 12,
            sm: 6,
          },
          {
            name: "commonTerms.dataDeclaration",
            type: "text",
            label: "Translation for Data declaration",
            placeholder: "Enter translation for Data declaration",
            tooltip: "Translation word for term Data declaration.",
            required: false,
            default: {
              en: "Data declaration",
              fi: "Tietosuojailmoitus",
              sv: "Sekretessmeddelande",
              nl: "Datadeclaratie",
            },
            highlight: true,
            xs: 12,
            sm: 6,
          },
          {
            name: "commonTerms.legIntClaim",
            type: "text",
            label: "Translation for Legitimate Claim",
            placeholder: "Enter translation for Legitimate Claim",
            tooltip: "Translation word for term Legitimate Claim.",
            required: false,
            default: {
              en: "Legitimate Claim",
              fi: "Käsittelyperuste",
              sv: "Legitimt krav",
              nl: "Legitieme claim",
            },
            highlight: true,
            xs: 12,
            sm: 6,
          },
          {
            name: "commonTerms.dataDeclarationNameHeader",
            type: "text",
            label: "Translation for Data declaration name header",
            placeholder: "Enter translation for Data declaration name header",
            tooltip: "Translation word for term Data declaration name header.",
            required: false,
            default: {
              en: "Name",
              fi: "Hyödynnettävä tieto",
              sv: "Namn",
              nl: "Naam",
            },
            highlight: true,
            xs: 12,
            sm: 6,
          },
          {
            name: "commonTerms.dataDeclarationIdHeader",
            type: "text",
            label: "Translation for Data declaration Id header",
            placeholder: "Enter translation for Data declaration Id header",
            tooltip: "Translation word for term Data declaration Id header.",
            required: false,
            default: { en: "Id", fi: "Id", sv: "Id", nl: "ID" },
            highlight: true,
            xs: 12,
            sm: 6,
          },
          {
            name: "commonTerms.dataDeclarationDescriptionHeader",
            type: "text",
            label: "Translation for Data declaration Description header",
            placeholder:
              "Enter translation for Data declaration Description header",
            tooltip:
              "Translation word for term Data declaration Description header.",
            required: false,
            default: {
              en: "Description",
              fi: "Selite",
              sv: "Beskrivning",
              nl: "Omschrijving",
            },
            highlight: true,
            xs: 12,
            sm: 6,
          },
          {
            name: "commonTerms.purposeIllustration",
            type: "text",
            label: "Translation for Purpose Illustration",
            placeholder: "Enter translation for Purpose Illustration",
            tooltip: "Translation word for term Purpose Illustration.",
            required: false,
            default: {
              en: "Illustration",
              fi: "Kuvaus",
              sv: "Förklaring",
              nl: "Illustratie",
            },
            highlight: true,
            xs: 12,
            sm: 6,
          },
          {
            name: "commonTerms.vendorCountDeclarationText",
            type: "text",
            label: "Translation for vendor count declaration text",
            placeholder: "Enter translation for vendor count declaration text",
            tooltip: "Translation word for term vendor count declaration text.",
            required: false,
            default: {
              en: "{VendorsCount} vendors can use this",
              fi: "{VendorsCount} toimittajaa voi käyttää tätä",
              sv: "{VendorsCount} Jag samtycker",
              nl: "{VendorsCount} verkopers kunnen dit gebruiken",
            },
            highlight: true,
            xs: 12,
            sm: 6,
          },
          {
            name: "commonTerms.retention",
            type: "text",
            label: "Translation for word retention",
            placeholder: "Enter translation for word retention",
            tooltip: "Translation word for  word retention",
            required: false,
            default: {
              en: "Retention time",
              fi: "Säilyttäminen",
              sv: "Förvaring",
              nl: "Retentie",
            },
            highlight: true,
            xs: 12,
            sm: 6,
          },
          {
            name: "commonTerms.cookiesDeclaration",
            type: "text",
            label: "Translation for word cookies declaration",
            placeholder: "Enter translation for word cookies declaration",
            tooltip: "Translation word for  word cookies declaration",
            required: false,
            default: {
              en: "Cookies Declaration",
              fi: "Evästeiden ilmoitus",
              sv: "Kakans förklaring",
              nl: "Cookiesverklaring",
            },
            highlight: true,
            xs: 12,
            sm: 6,
          },
        ],
      },
    ],
    style: [
      {
        name: "logoUrl",
        type: "text",
        label: "Logo Url",
        placeholder: "Enter your logo url",
        tooltip: "Url of logo to be used in CMP.",
        guiderText:
          "Note: This field is recommended to be changed according to your needs.",
        required: false,
        default:
          "https://cdn.gravito.net/logos/gravito_logo_white_background.png",
        xs: 12,
        sm: 12,
      },
      {
        name: "primaryColor",
        type: "color",
        label: "Primary color code",
        placeholder: "Enter your primary color code",
        tooltip: "Primary color to be used in CMP.",
        guiderText:
          "Note: This field is recommended to be changed according to your needs.",
        required: false,
        default: "#FF4010",
        xs: 12,
        sm: 6,
      },
      {
        name: "secondaryColor",
        type: "color",
        label: "Secondary color code",
        placeholder: "Enter your secondary color code",
        tooltip: "Secondary color to be used in CMP.",
        guiderText:
          "Note: This field is recommended to be changed according to your needs.",
        required: false,
        default: "#757474",
        xs: 12,
        sm: 6,
      },
      {
        name: "disableConfirmationModal",
        type: "checkbox",
        label: "Do you want to disable confirmation modal",
        tooltip: "Check this if you don't want to show confirmation modal.",
        required: false,
        default: true,
        xs: 12,
        sm: 12,
      },
      {
        name: "showCopyTcStringButton",
        type: "checkbox",
        label: "Do you want to show copy TcString button",
        tooltip:
          "Check this if you want to show copy TcString button on second layer.",
        required: false,
        default: true,
        xs: 12,
        sm: 12,
      },
      {
        name: "accessibilityEnabled",
        type: "checkbox",
        label: "Do you want to enable accessibility",
        tooltip: "Check this if you want to enable accessibility on CMP.",
        required: false,
        default: false,
        xs: 12,
        sm: 12,
      },
      {
        name: "showScrollBars",
        type: "checkbox",
        label: "Do you want to show scroll bars",
        tooltip: "Check this if you want to show scroll bars on CPM.",
        required: false,
        default: false,
        xs: 12,
        sm: 6,
      },
      {
        name: "scrollBarWidth",
        type: "text",
        label: "Scroll bar width",
        placeholder: "Enter scroll bar width",
        tooltip: "Width of scroll bar to be used in CMP.",
        required: false,
        default: "10px",
        xs: 12,
        sm: 6,
      },
      {
        name: "customCSS",
        type: "customCSS",
        label: "Custom CSS:-",
        placeholder: "Enter your custom CSS",
        multiline: true,
        minRows: 4,
        tooltip: "Use this text area to add custom CSS to CMP.",
        required: false,
        default: "",
        xs: 12,
        sm: 12,
      },
      {
        name: "layoutType",
        type: "select",
        label: "Layout Type",
        placeholder: "Select layout type",
        options: [
          {
            label: "2-tabs",
            value: "2-tabs",
          },
          {
            label: "3-tabs",
            value: "3-tabs",
          },
        ],
        tooltip: "Select the layout type for CMP.",
        required: false,
        default: "2-tabs",
        xs: 12,
        sm: 6,
      },
      {
        name: "consentInputType",
        type: "select",
        label: "Consent Input Type",
        placeholder: "Select consent input type",
        options: [
          {
            label: "toggle",
            value: "toggle",
          },
          {
            label: "checkbox",
            value: "checkbox",
          },
        ],
        tooltip: "Select the type of control you want to use for consent.",
        required: false,
        default: "checkbox",
        xs: 12,
        sm: 6,
      },
      {
        name: "useAccordionForPurpose",
        type: "checkbox",
        label: "Do you want to use accordion for purpose",
        tooltip: "Check this if you want to use Accordion for purposes.",
        required: false,
        default: true,
        xs: 12,
        sm: 12,
      },
      {
        name: "fonts",
        type: "fonts",
        label: "fonts",
        helperText: "Using this section you will be able to add fonts.",
        required: false,
        default: [
          {
            url: "https://cdn.gravito.net/fonts/lato-v22-latin-700.woff2",
            unicodeRange:
              "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;",
          },
          {
            url: "https://cdn.gravito.net/fonts/lato-v22-latin-700.woff2",
            unicodeRange:
              "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;",
          },
        ],
        xs: 12,
        sm: 12,
      },
    ],
    GtmTagModal: [
      {
        name: "triggerName",
        type: "text",
        label: "Trigger name",
        placeholder: "Enter tag trigger name",
        tooltip: "Name of the events that needs to added to dataLayer.",
        default: "",
        required: false,
        xs: 12,
        sm: 12,
      },
      {
        name: "consentIds",
        type: "multiSelect",
        label: "Consent IDs",
        placeholder: "Select consent IDs",
        options: [],
        tooltip: "Ids of consents that gtm should watch.",
        default: [],
        xs: 12,
        sm: 12,
      },
      {
        name: "fireOnce",
        type: "checkbox",
        label: "Fire Once",
        tooltip:
          "Check this checkbox if you want to fire the gtm tag only once when the consent changes.",
        default: false,
        required: false,
        xs: 12,
        sm: 12,
      },
    ],
    ConsentModal: [
      {
        name: "type",
        type: "select",
        label: "Consent Type",
        placeholder: "Select consent type",
        options: [
          {
            label: "purposes",
            value: "purposes",
          },
          {
            label: "stacks",
            value: "stacks",
          },
        ],
        default: "",
        tooltip: "Type of consent you want to take (Stacks or Purposes)",
        required: false,
        xs: 12,
        sm: 12,
      },
      {
        name: "id",
        type: "number",
        label: "Consent ID",
        placeholder: "Enter Consent ID",
        tooltip: "Id of Purpose or Stack you want to use",
        default: "",
        required: false,
        xs: 12,
        sm: 12,
      },
    ],
    NonTCFVendorModal: [
      {
        name: "id",
        type: "number",
        label: "Id",
        placeholder: "Enter Non TCF Vendor ID",
        tooltip: "Id of Non TCF Vendor",
        default: "",
        required: false,
        xs: 12,
        sm: 12,
      },
      {
        name: "name",
        type: "text",
        label: "Name",
        placeholder: "Enter Non TCF Vendor Name",
        tooltip: "Name of Non TCF Vendor",
        default: "",
        required: false,
        xs: 12,
        sm: 12,
      },
      {
        name: "description",
        type: "text",
        label: "Description",
        placeholder: "Enter Non TCF Vendor Description",
        multiline: true,
        minRows: 3,
        tooltip: "Description of Non TCF vendor",
        default: "",
        required: false,
        xs: 12,
        sm: 12,
      },
      {
        name: "isConsentable",
        type: "checkbox",
        label: "Is Consentable",
        tooltip:
          "This flag will decide whether your custom purpose is consentable or not.",
        default: false,
        required: false,
        xs: 12,
        sm: 12,
      },
      {
        name: "cookiesDeclaration",
        type: "cookiesDeclaration",
        label: "Cookie Declarations",
        tooltip: "Cookie Declarations of Non TCF vendor",
        schema: [
          {
            name: "name",
            type: "text",
            label: "Name",
            placeholder: "Enter cookie name",
            tooltip: "Name of the cookie",
            default: "",
            required: false,
            xs: 12,
            sm: 12,
          },
          {
            name: "description",
            type: "text",
            label: "Description",
            placeholder: "Enter cookie description",
            tooltip: "Description of the cookie",
            default: "",
            required: false,
            xs: 12,
            sm: 12,
          },
          {
            name: "expiresInDays",
            type: "number",
            label: "Expires In Days",
            placeholder: "Enter cookie expires in days",
            tooltip: "Expiry of the cookie",
            default: "",
            required: false,
            xs: 12,
            sm: 12,
          },
        ],
        default: [],
        xs: 12,
        sm: 12,
      },
    ],
    CustomPurposeModal: [
      {
        name: "id",
        type: "number",
        label: "Id",
        placeholder: "Enter your custom purpose id",
        tooltip: "Id of your custom purpose",
        default: "",
        required: false,
        xs: 12,
        sm: 12,
      },
      {
        name: "name",
        type: "text",
        label: "Name",
        placeholder: "Enter your custom purpose name",
        tooltip:
          "Name of your custom purpose.This will be shown as title of custom purpose",
        default: "",
        required: false,
        xs: 12,
        sm: 12,
      },
      {
        name: "description",
        type: "text",
        label: "Description",
        placeholder: "Enter your custom purpose description",
        multiline: true,
        minRows: 3,
        tooltip: "This will be shown as description of your custom purpose",
        default: "",
        required: false,
        xs: 12,
        sm: 12,
      },
      {
        name: "descriptionLegal",
        type: "text",
        label: "Legal Description",
        placeholder: "Enter your custom purpose legal description",
        multiline: true,
        minRows: 3,
        tooltip:
          "This will be shown as legal description of your custom purpose",
        default: "",
        required: false,
        xs: 12,
        sm: 12,
      },
      {
        name: "isLegitimate",
        type: "checkbox",
        label: "Is Legitimate",
        tooltip:
          "This flag will decide whether your custom purpose is legitimate or not.",
        default: false,
        required: false,
        xs: 12,
        sm: 12,
      },
      {
        name: "isConsentable",
        type: "checkbox",
        label: "Is Consentable",
        tooltip:
          "This flag will decide whether your custom purpose is Consentable or not.",
        default: true,
        required: false,
        xs: 12,
        sm: 12,
      },
    ],
    publisherRestrictionModal: [
      {
        name: "purposeID",
        type: "number",
        label: "Purpose ID",
        placeholder: "Enter purpose ID to restrict",
        tooltip: "Id of purpose on which restriction is to be applied.",
        default: "",
        required: false,
        xs: 12,
        sm: 12,
      },
      {
        name: "restrictionType",
        type: "select",
        label: "Restriction Type",
        placeholder: "Select your restriction type",
        options: [
          {
            label: "NOT_ALLOWED",
            value: "NOT_ALLOWED",
          },
          {
            label: "REQUIRE_CONSENT",
            value: "REQUIRE_CONSENT",
          },
          {
            label: "REQUIRE_LI",
            value: "REQUIRE_LI",
          },
        ],
        tooltip:
          "Select the type of restriction you want apply on this purpose",
        default: "",
        required: false,
        xs: 12,
        sm: 12,
      },
      {
        name: "vendors",
        type: "multiSelect",
        label: "Vendors to restrict",
        placeholder: "Select vendors to restrict",
        options: [],
        tooltip: "ID of vendor on which purpose restriction should be applied",
        default: [],
        xs: 12,
        sm: 12,
      },
    ],
    FontModal: [
      {
        name: "url",
        type: "text",
        label: "Font URL",
        placeholder: "Enter font url",
        tooltip: "This will be the url of font.",
        default: "",
        required: false,
        xs: 12,
        sm: 12,
      },
      {
        name: "unicodeRange",
        type: "text",
        label: "Font Unicode Range",
        placeholder: "Enter font unicode range",
        multiline: true,
        minRows: 3,
        tooltip: "This will be the unicode range of font.",
        default: "",
        required: false,
        xs: 12,
        sm: 12,
      },
    ],
  },
  languages: {
    english: {
      label: "English",
      languageCode: "en",
      iconUrl: "https://cdn.gravito.net/logos/English.png",
    },
    finnish: {
      label: "Finnish",
      languageCode: "fi",
      iconUrl: "https://cdn.gravito.net/logos/Finnish.png",
    },
    swedish: {
      label: "Swedish",
      languageCode: "sv",
      iconUrl: "https://cdn.gravito.net/logos/Swedish.png",
    },
    dutch: {
      label: "Dutch",
      languageCode: "nl",
      iconUrl: "https://cdn.gravito.net/logos/Dutch.png",
    },
  },
};
export default schema;
